import React from 'react';
import { useAuth } from 'react-oidc-context';
import { AuthStateContext } from '.';

/**
 * Provides access to user and authentication (keycloak) data about the logged in user.
 *
 * @param {*} props
 * @return {*}
 */
const AuthStateContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const auth = useAuth();

  // Add event listener for silent renew errors
  auth.events.addSilentRenewError(() => {
    // If the silent renew fails, ensure the user is signed out and redirect to the home page
    auth.signoutRedirect();
  });

  React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
  }, [auth, auth.events, auth.signinSilent]);

  return (
    <AuthStateContext.Provider
      value={{
        auth
      }}>
      {props.children}
    </AuthStateContext.Provider>
  );
};

export default AuthStateContextProvider;
