import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
///import { useConfigContext } from '.';

interface WebSocketContextType {
  sendMessage: (message: string) => void;
  lastMessage: MessageEvent | null;
  connectionStatus: string;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

interface WebSocketProviderProps {
  children: ReactNode;
  walletId: string;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children, walletId }) => {
  //const configContext = useConfigContext();

  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const [socketUrl] = useState(`${protocol}://${window.location.host}/ws`);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const connectionStatus =
    {
      0: 'Connecting',
      1: 'Open',
      2: 'Closing',
      3: 'Closed'
    }[readyState as 0 | 1 | 2 | 3] || 'Unknown';

  useEffect(() => {
    try {
      const message = {
        walletId: walletId,
        message: 'Connected!!'
      };
      sendMessage(JSON.stringify(message));
      console.log('Connected to ws');
    } catch (error) {
      console.error(error);
    }
  }, [readyState, sendMessage]);

  return (
    <WebSocketContext.Provider value={{ sendMessage, lastMessage, connectionStatus }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocketContext must be used within a WebSocketProvider');
  }
  return context;
};
