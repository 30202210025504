import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { FullScreenSpinner } from '../components/molecules/Spinner';
import { useAuthStateContext, useConfigContext } from '../contexts';
import GenericButton from '../components/molecules/buttons/GenericButton';
import { IReservation } from '../types/reservation';
import { useUserService } from '../services';

const OnboardPage: React.FC = () => {
  const configContext = useConfigContext();
  const authStateContext = useAuthStateContext();
  const userService = useUserService();

  const [reservation, setReservation] = useState<IReservation | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const email = String(authStateContext.auth.user?.profile.email);
  const reservationId = String(location.pathname.split('/').pop());

  useEffect(() => {
    const initialize = async () => {
      try {
        setLoading(true);
        const fetchedReservation = await userService.fetchReservation(reservationId);

        if (fetchedReservation.email.toLowerCase() !== email.toLowerCase()) {
          throw new Error('Invalid reservation');
        }

        setReservation(fetchedReservation);
        const walletResponse = await userService.createWallet();

        if (walletResponse) {
          const walletUrl = configContext.WALLET_HOST;
          window.open(walletUrl, '_self');
        }
      } catch (error) {
        console.error('Error during onboarding:', error);
        setLoading(false);
      }
    };

    initialize();
  }, [email, reservationId, userService, configContext]);

  if (loading) {
    return (
      <>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', gap: 50 }}>
          <FullScreenSpinner text={'Creating Wallet...'} />
        </View>
      </>
    );
  }

  if (!reservation) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ marginBottom: 16, padding: 8 }}>
          <div>
            <Text style={styles.title}>No Reservation Found</Text>
            <div />

            <GenericButton
              title="Looking for Wallet? Click Here"
              onPress={() => window.open(configContext.WALLET_HOST, '_blank')}
              isPrimary
            />
          </div>
        </div>
      </View>
    );
  }
  return <FullScreenSpinner />;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 16
  },
  title: {
    fontSize: 24,
    textAlign: 'center'
  },
  details: {
    fontSize: 16,
    marginBottom: 16
  }
});

export default OnboardPage;
