import { useAuth } from 'react-oidc-context';
import { useMemo, useEffect } from 'react';
import UserService from './user';
import AdminService from './admin';
import IssuerService from './issuer';

/**
 * useUserService
 *
 * @return {*}  {UserService}
 */
export const useUserService = (): UserService => {
  const auth = useAuth();
  const user = new UserService(auth);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userService = useMemo(() => user, []);
  useEffect(() => {
    if (auth?.user?.access_token && userService.getToken() !== auth.user.access_token) {
      userService.refreshToken(auth.user.access_token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.access_token]);

  return userService;
};

/**
 * useIssuerService
 *
 * @return {*}  {AdminService}
 */
export const useAdminService = (): AdminService => {
  const auth = useAuth();
  const admin = new AdminService(auth);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const adminService = useMemo(() => admin, []);

  useEffect(() => {
    if (auth?.user?.access_token && adminService.getToken() !== auth.user.access_token) {
      adminService.refreshToken(auth.user.access_token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.access_token]);

  return adminService;
};

/**
 * useIssuerService
 *
 * @return {*}  {IssuerService}
 */
export const useIssuerService = (): IssuerService => {
  const auth = useAuth();
  const issuer = new IssuerService(auth);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const issuerService = useMemo(() => issuer, []);

  useEffect(() => {
    if (auth?.user?.access_token && issuerService.getToken() !== auth.user.access_token) {
      issuerService.refreshToken(auth.user.access_token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user?.access_token]);

  return issuerService;
};
