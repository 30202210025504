import React, { useState } from 'react';
import { Text, StyleSheet, View } from 'react-native';
import EmptyCredentialIcon from '../../../components/icons/EmptyCredentialIcon';
import GenericButton from '../../../components/molecules/buttons/GenericButton';
import DeleteConfirmModal from '../../../modal/DeleteConfirmModal';
import Layout from '../../../components/layout/Layout';
import { useIssuerService } from '../../../services';
import { useTenantContext } from '../../../contexts';
import GenericBack from '../../../components/molecules/buttons/GenericBack';
import InviteUserButton from '../../../components/molecules/buttons/InviteUserButton';
import { IReservation } from '../../../types/reservation';

interface IReservationUserDetailsProps {
  item: IReservation;
  setVisible: (value: boolean) => void;
}

const ReservationUserDetails: React.FC<IReservationUserDetailsProps> = ({ item, setVisible }) => {
  const issuerService = useIssuerService();
  const { refresh } = useTenantContext();

  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);

  const openDeleteUserModal = () => {
    setDeleteUserModalVisible(true);
  };

  const closeDeleteUserModal = () => {
    setDeleteUserModalVisible(false);
  };

  const deleteUser = async () => {
    try {
      // Delete user without wallet
      await issuerService.deleteUserReservation(item.reservation_id);
      // const response =
      // console.log('response', response);
      //TODO: Trigger popup with message from body

      refresh();
      setVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout title="Issuer Application">
      <DeleteConfirmModal
        isVisible={deleteUserModalVisible}
        onClose={closeDeleteUserModal}
        onConfirm={deleteUser}
      />
      <View style={styles.Container}>
        <GenericBack
          onPress={() => setVisible(false)}
          buttonStyle={styles.backButton}
          textStyle={styles.buttonText}
        />
        <View style={styles.inviteContainer}>
          <View>
            <Text style={styles.nameHeader}>{item?.tenant_name}</Text>
            <Text style={styles.secondaryText}>{item?.email}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <InviteUserButton
              title={'Resend Invitation'}
              reservation={item}
              buttonStyle={styles.button}
              textStyle={styles.buttonText}
            />
            <GenericButton
              title={'Delete Wallet'}
              onPress={openDeleteUserModal}
              buttonStyle={styles.deleteReservationButton}
              textStyle={styles.buttonText}
              isPrimary={true}
            />
          </View>
        </View>
        <View>
          <Text style={styles.secondaryText}>{`Reservation id: ${item?.reservation_id}`}</Text>
          <Text style={styles.secondaryText}>{`Reservation pwd: ${item?.reservation_pwd}`}</Text>
        </View>

        <View style={styles.emptyCredentialContainer}>
          <EmptyCredentialIcon />
          <Text style={styles.noCredentialHeaderText}>No wallet</Text>
          <Text
            style={
              styles.secondaryText
            }>{`${item?.tenant_name} has not accepted their invitation yet`}</Text>
          <InviteUserButton
            title={'Resend Invitation'}
            reservation={item}
            buttonStyle={styles.button}
            textStyle={styles.buttonText}
          />
        </View>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    width: '100%'
  },
  emptyCredentialContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  credentialContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  button: {
    width: 141,
    height: 41,
    borderRadius: 6,
    margin: 4
  },
  deleteReservationButton: {
    width: 156,
    height: 41,
    borderRadius: 6,
    margin: 4
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  },
  inviteContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  nameHeader: {
    fontSize: 32,
    fontWeight: 700,
    color: '#000000'
  },
  primaryText: {
    fontSize: 16,
    fontWeight: 700,
    color: '#000000'
  },
  secondaryText: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000000'
  },
  credentialText: {
    fontSize: 18,
    fontWeight: 700,
    color: '#000',
    marginTop: 21
  },
  noCredentialHeaderText: {
    fontSize: 24,
    fontWeight: 700,
    color: '#000'
  },
  revokeButton: {
    width: 83,
    height: 41,
    borderRadius: 6,
    margin: 4,
    marginRight: 24
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 2,
    marginBottom: 16
  }
});

export default ReservationUserDetails;
