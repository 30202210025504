import React from 'react';
import { StyleSheet, Text, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';

type GenericButtonProps = {
  title: string;
  onPress: () => void;
  isPrimary: boolean;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
  isDisabled?: boolean;
};

const GenericButton: React.FC<GenericButtonProps> = ({
  title,
  onPress,
  isPrimary,
  isDisabled = false,
  buttonStyle,
  textStyle
}) => {
  return (
    <TouchableOpacity
      style={[
        isPrimary ? styles.primaryButton : styles.secondaryButton,
        isDisabled && styles.disabledButton,
        buttonStyle
      ]}
      onPress={!isDisabled ? onPress : undefined}
      disabled={isDisabled}>
      <Text
        style={[
          isPrimary ? styles.primaryButtonText : styles.secondaryButtonText,
          isDisabled && styles.disabledText,
          textStyle
        ]}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  primaryButton: {
    backgroundColor: '#1C274C',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center'
  },
  primaryButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '600'
  },
  secondaryButton: {
    backgroundColor: 'white',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: 'black',
    alignItems: 'center',
    justifyContent: 'center'
  },
  secondaryButtonText: {
    color: 'black',
    fontSize: 16,
    fontWeight: '600'
  },
  disabledButton: {
    backgroundColor: '#B0B0B0',
    borderColor: '#A0A0A0',
    opacity: 0.6
  },
  disabledText: {
    color: '#F0F0F0'
  }
});

export default GenericButton;
