import React from 'react';
import { useTenantContext } from '../contexts';
import BlankModal from './BlankModal';
import UserReservationDetails from '../components/modals/UserReservationDetails';
import { IReservation } from '../types/reservation';
import { useIssuerService } from '../services';

export interface SendUserInviteModalProps {
  isVisible: boolean;
  onClose: () => void;
  reservation: IReservation;
}

const SendUserInviteModal: React.FC<SendUserInviteModalProps> = ({
  isVisible,
  onClose,
  reservation
}) => {
  const issuerService = useIssuerService();
  const tenantContext = useTenantContext();

  const sendEmail = async (data: IReservation) => {
    await issuerService.sendEmail(data.email);
    await tenantContext.fetchReservations();
    onClose();
  };

  if (!reservation) return null;

  return (
    <BlankModal
      title="Resend Invitation"
      isVisible={isVisible}
      onClose={onClose}
      onConfirm={() => sendEmail(reservation)}>
      <UserReservationDetails reservation={reservation} />
    </BlankModal>
  );
};

export default SendUserInviteModal;
