import axios, { AxiosInstance } from 'axios';
import { AuthContextProps } from 'react-oidc-context';

/**
 * BaseService class
 *
 * @export
 * @class BaseService
 */
export default class BaseService {
  private auth: AuthContextProps;
  axiosInstance: AxiosInstance;

  constructor(auth: AuthContextProps) {
    this.auth = auth;
    this.axiosInstance = axios.create({
      baseURL: '/api',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth.user?.access_token}`
      }
    });
  }
  getToken(): string | undefined {
    const authHeader = this.axiosInstance.defaults.headers.Authorization;
    if (typeof authHeader === 'string' && authHeader.startsWith('Bearer ')) {
      return authHeader.substring(7);
    }

    return typeof authHeader === 'string' ? authHeader : undefined;
  }

  refreshToken(token: string): void {
    this.axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  }
  /**
   * Get data
   *
   * @template T
   * @param {string} url
   * @return {*}  {Promise<T>}
   * @memberof BaseService
   */
  async get<T>(url: string): Promise<T> {
    try {
      const res = await this.axiosInstance.get<T>(url);
      return res.data;
    } catch (e: any) {
      console.error(e.message);
      throw new Error('Failed to fetch data');
    }
  }

  /**
   * Post data
   *
   * @template T
   * @param {string} url
   * @param {object} data
   * @return {*}  {Promise<T>}
   * @memberof BaseService
   */
  async post<T>(url: string, data: object): Promise<T> {
    try {
      const res = await this.axiosInstance.post<T>(url, data);
      return res.data;
    } catch (e: any) {
      console.error(e.message);
      throw new Error('Failed to post data');
    }
  }
}
