import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { useTenantContext } from '../../../contexts';
import DeleteConfirmModal from '../../../modal/DeleteConfirmModal';
import Layout from '../../../components/layout/Layout';
import GenericButton from '../../../components/molecules/buttons/GenericButton';
import CredentialList from './CredentialList';
import GenericBack from '../../../components/molecules/buttons/GenericBack';
import { IUser } from '../../../types/user';
import { FullScreenSpinner } from '../../../components/molecules/Spinner';
import SendUserCredModal from '../../../modal/SendUserCredModal';
import { useIssuerService } from '../../../services';

interface IWalletUserDetailsProps {
  item: IUser;
  setVisible: (value: boolean) => void;
  visible: boolean;
}

const WalletUserDetails: React.FC<IWalletUserDetailsProps> = ({ item, setVisible, visible }) => {
  const { fetchCredentials, fetchUsers } = useTenantContext();
  const issuerService = useIssuerService();

  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState<any[]>([]);
  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);
  const [sendUserCredModalOpen, setSendUserCredModalOpen] = useState(false);

  useEffect(() => {
    const getCredentials = async () => {
      setLoading(true);
      if (item && item.connection_id) {
        const newCredentials = await fetchCredentials(item.connection_id);
        setCredentials(newCredentials);
      }
      setLoading(false);
    };
    if (visible) {
      getCredentials();
    }
  }, [item, fetchCredentials, visible]);

  const openDeleteUserModal = () => {
    setDeleteUserModalVisible(true);
  };

  const closeDeleteUserModal = () => {
    setDeleteUserModalVisible(false);
  };

  const deleteUser = async () => {
    try {
      // Delete user with wallet
      const response = await issuerService.deleteUser(item.email);
      if (response) {
        await fetchUsers();
        setVisible(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout title="Issuer Application">
      <DeleteConfirmModal
        isVisible={deleteUserModalVisible}
        onClose={closeDeleteUserModal}
        onConfirm={deleteUser}
      />
      <SendUserCredModal
        isVisible={sendUserCredModalOpen}
        onClose={() => setSendUserCredModalOpen(false)}
        user={item}
      />
      <View style={styles.Container}>
        <GenericBack onPress={() => setVisible(false)} buttonStyle={styles.backButton} />
        <View style={styles.inviteContainer}>
          <View>
            <Text style={styles.nameHeader}>{item?.tenant_name}</Text>
            <Text style={styles.secondaryText}>{item?.email}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <GenericButton
              title={'Issue Credential'}
              onPress={() => setSendUserCredModalOpen(true)}
              buttonStyle={styles.button}
              textStyle={styles.buttonText}
              isPrimary={true}
            />
            <GenericButton
              title={'Delete Wallet'}
              onPress={openDeleteUserModal}
              buttonStyle={styles.button}
              textStyle={styles.buttonText}
              isPrimary={true}
            />
          </View>
        </View>
        <View>
          <Text style={styles.secondaryText}>{`Tenant ID: ${item.tenant_id}`}</Text>
          <Text style={styles.secondaryText}>{`Wallet ID: ${item?.wallet_id}`}</Text>
        </View>
        <>
          <View>
            <Text style={styles.credentialText}>{`Credentials (${credentials.length})`}</Text>
          </View>
          {loading ? (
            <FullScreenSpinner />
          ) : (
            <CredentialList
              item={item}
              credentials={credentials}
              setSendUserCredModalOpen={setSendUserCredModalOpen}
            />
          )}
        </>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    width: '100%'
  },
  emptyCredentialContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  credentialContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  button: {
    width: 141,
    height: 41,
    borderRadius: 6,
    margin: 4
  },
  deleteReservationButton: {
    width: 156,
    height: 41,
    borderRadius: 6,
    margin: 4
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  },
  inviteContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  nameHeader: {
    fontSize: 32,
    fontWeight: 700,
    color: '#000000'
  },
  primaryText: {
    fontSize: 16,
    fontWeight: 700,
    color: '#000000'
  },
  secondaryText: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000000'
  },
  credentialText: {
    fontSize: 18,
    fontWeight: 700,
    color: '#000',
    marginTop: 21
  },
  noCredentialHeaderText: {
    fontSize: 24,
    fontWeight: 700,
    color: '#000'
  },
  revokeButton: {
    width: 83,
    height: 41,
    borderRadius: 6,
    margin: 4,
    marginRight: 24
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 2,
    marginBottom: 16
  }
});

export default WalletUserDetails;
