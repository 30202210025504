import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { buildUrl } from './utils/Utils.tsx';
import { WebStorageStateStore } from 'oidc-client-ts';
import ConfigContextProvider from './contexts/ConfigContext.tsx';
import { AuthStateContext, ConfigContext } from './contexts/index.tsx';
import AuthStateContextProvider from './contexts/authStateContext.tsx';

createRoot(document.getElementById('root')!).render(
  <ConfigContextProvider>
    <ConfigContext.Consumer>
      {(config) => {
        if (!config) {
          return;
        }

        const authConfig: AuthProviderProps = {
          authority: `${config.KEYCLOAK_URL}/realms/${config.KEYCLOAK_REALM}/`,
          client_id: config.KEYCLOAK_CLIENT_ID,
          resource: config.KEYCLOAK_CLIENT_ID,
          // Automatically renew the access token before it expires
          automaticSilentRenew: true,
          // Default sign in redirect
          redirect_uri: buildUrl(window.location.origin),
          // Default sign out redirect
          post_logout_redirect_uri: buildUrl(window.location.origin),
          // Automatically load additional user profile information
          loadUserInfo: true,
          userStore: new WebStorageStateStore({ store: window.localStorage }),
          onSigninCallback: (): void => {
            // See https://github.com/authts/react-oidc-context#getting-started
            window.history.replaceState({}, document.title, window.location.pathname);
          }
        };
        return (
          <AuthProvider {...authConfig}>
            <AuthStateContextProvider>
              <AuthStateContext.Consumer>
                {(authState) => {
                  if (!authState || (authState.auth.isLoading && !authState.auth.isAuthenticated && !authState.auth.user?.access_token)) {
                    return;
                  }
                  return <App />;
                }}
              </AuthStateContext.Consumer>
            </AuthStateContextProvider>
          </AuthProvider>
        );
      }}
    </ConfigContext.Consumer>
  </ConfigContextProvider>
);
