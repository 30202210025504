import React, { useContext, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { TenantContext } from '../contexts';
import { FullScreenSpinner } from '../components/molecules/Spinner';
import UsersTable from '../features/table/UsersTable';
import { useWebSocketContext } from '../contexts/WebSocketContext';

const TenantPage: React.FC = () => {
  const tenantContext = useContext(TenantContext);
  const { lastMessage } = useWebSocketContext();

  useEffect(() => {
    const fetchData = async () => {
      await tenantContext.fetchReservations();
      await tenantContext.fetchUsers();
    };
    if (lastMessage?.data === 'refresh-users' || lastMessage?.data === 'refresh-credentials') {
      fetchData();
    }
  }, [lastMessage]);

  useEffect(() => {
    const fetchData = async () => {
      await tenantContext.fetchTenant();
      await tenantContext.fetchReservations();
      await tenantContext.fetchUsers();
    };

    if (!tenantContext.tenant || !tenantContext.reservations || !tenantContext.users) {
      fetchData();
    }
  }, [tenantContext]);

  if (
    !tenantContext ||
    !tenantContext.tenant ||
    !tenantContext.reservations ||
    !tenantContext.users
  ) {
    return <FullScreenSpinner />;
  }

  return (
    <View style={styles.container}>
      <UsersTable />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 8,
    paddingTop: 0
  },
  navigatorContainer: {
    flex: 1,
    paddingRight: 8
  },
  consoleContainer: {
    flex: 3,
    paddingLeft: 8
  }
});

export default TenantPage;
