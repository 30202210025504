import React from 'react';
import { useAuth } from 'react-oidc-context';
import { PathRouteProps } from 'react-router-dom';
import { FullScreenSpinner } from '../components/molecules/Spinner';

interface AuthGuardProps extends PathRouteProps {
  fallback: JSX.Element;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const auth = useAuth();

  if (!children) return null;

  if (auth.isLoading && !auth.isAuthenticated) {
    return <FullScreenSpinner />;
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect({ redirect_uri: window.location.href });
  }

  return children;
};

export default AuthGuard;
