import { PropsWithChildren } from 'react';
import { Modal, StyleSheet, View, Dimensions, Text } from 'react-native';
import GenericButton from '../components/molecules/buttons/GenericButton';
import GenericCancel from '../components/molecules/buttons/GenericCancel';

const { width } = Dimensions.get('window');

export interface ModalActions {
  isVisible: boolean; // Boolean to control modal visibility
  onClose: () => void; // Function to handle modal close
  title: string; // Modal title
  children: React.ReactNode; // Content inside the modal (passed as children)
  onConfirm: () => void; // Function to handle modal confirm
  isLoading?: boolean; // Boolean to control loading state
  containerStyle?: object; // Custom style for modal container
  overlayStyle?: object; // Custom style for modal overlay
  acceptText?: string; // Text for the accept button
  changeCredentialType?: boolean; //
  setIsAnonCred?: (value: boolean) => void;
  isAnonCred?: boolean;
}

const BlankModal: React.FC<PropsWithChildren<ModalActions>> = ({
  isVisible,
  onClose,
  title,
  children,
  onConfirm,
  containerStyle = {},
  overlayStyle = {},
  acceptText,
  isLoading = false
}) => {
  return (
    <Modal transparent visible={isVisible} animationType="fade" onRequestClose={onClose}>
      <View style={[styles.modalOverlay, overlayStyle]}>
        <View style={[styles.modalContainer, containerStyle]}>
          {/* Modal Title */}
          {title && (
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{title}</Text>
            </View>
          )}

          {/* Modal Content */}
          <View style={styles.content}>{children}</View>

          {/* Modal Buttons */}
          <View style={styles.buttonContainer}>
            <GenericCancel
              onPress={() => onClose()}
              buttonStyle={{ marginRight: 8 }}
              isDisabled={isLoading}
            />
            <GenericButton
              title={acceptText || 'Invite'}
              onPress={() => onConfirm()}
              isPrimary
              isDisabled={isLoading}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContainer: {
    width: width * 0.4,
    padding: 20,
    borderRadius: 10,
    backgroundColor: '#fff',
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.2)'
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    justifyContent: 'space-between'
  },
  switchLabel: {
    fontSize: 16,
    marginRight: 10
  },
  switchValue: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 10
  },
  content: {
    marginBottom: 20
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  button: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 5,
    marginLeft: 10
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold'
  }
});

export default BlankModal;
