import { View, Text, StyleSheet, Pressable, Modal } from 'react-native';
import React, { useState } from 'react';
import WalletUserDetails from './WalletUserDetails';
import { IUser } from '../../../types/user';
import SendCredButton from '../../../components/molecules/buttons/SendCredButton';

interface IWalletUserItemProps {
  item: IUser;
}

/**
 * WalletUserItem component
 *
 * @param {*} { item }
 * @return {*}
 */
const WalletUserItem: React.FC<IWalletUserItemProps> = ({ item }) => {
  const [visible, setVisible] = useState(false);

  const onOpenDetails = () => {
    setVisible(true);
  };

  return (
    <>
      <Modal transparent visible={visible}>
        <WalletUserDetails item={item} setVisible={setVisible} visible={visible} />
      </Modal>
      <Pressable onPress={() => onOpenDetails()}>
        <View style={styles.card}>
          <View>
            <Text style={styles.name}>{item.tenant_name}</Text>
            <Text style={styles.email}>{item.email}</Text>
          </View>
          <SendCredButton title={'Issue Credential'} user={item} />
        </View>
      </Pressable>
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    borderTopWidth: 1,
    borderTopColor: '#000000',
    margin: 8,
    padding: 4,
    paddingTop: 20,
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  name: {
    fontSize: 16,
    fontWeight: '700',
    color: '#000000'
  },
  email: {
    fontSize: 16,
    fontWeight: '400',
    color: '#000000'
  },
  button: {
    width: 150,
    height: 41,
    borderRadius: 6
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  }
});

export default WalletUserItem;
