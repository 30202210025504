import { AuthContextProps } from 'react-oidc-context';
import UserService from './user';

/**
 * AdminService class
 *
 * @export
 * @class AdminService
 */
export default class AdminService extends UserService {
  constructor(auth: AuthContextProps) {
    super(auth);
  }
  /**
   * Fetch test
   *
   * @return {*}
   * @memberof AdminService
   */
  async fetchTest(): Promise<string> {
    const body = await this.get<string>('/admin/auth/test');
    return body;
  }
}
