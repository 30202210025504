import React from 'react';
import GenericButton from './GenericButton';
import { useDialogContext } from '../../../contexts';
import { StyleSheet } from 'react-native';
import { IUser } from '../../../types/user';

interface ISendCredButtonProps {
  title: string;
  user: IUser;
}

const SendCredButton: React.FC<ISendCredButtonProps> = ({ title, user }) => {
  const dialogContext = useDialogContext();

  return (
    <GenericButton
      title={title}
      onPress={() => dialogContext.sendUserCredDialog(user)}
      isPrimary={true}
      buttonStyle={styles.button}
      textStyle={styles.buttonText}
    />
  );
};

const styles = StyleSheet.create({
  button: {
    width: 142,
    height: 41,
    borderRadius: 6
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  }
});

export default SendCredButton;
