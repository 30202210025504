import './App.css';
import AppRouter from './routes/AppRouter';
import Layout from './components/layout/Layout';
import React from 'react';
import { DialogProvider } from './contexts/DialogContext';
import ToastProvider from './contexts/ToastContext';
import { WebSocketProvider } from './contexts/WebSocketContext';

function App(): React.ReactElement {
  return (
    <WebSocketProvider walletId={'issuer'}>
      <Layout title="Issuer Application">
        <DialogProvider>
          <ToastProvider>
            <AppRouter />
          </ToastProvider>
        </DialogProvider>
      </Layout>
    </WebSocketProvider>
  );
}

export default App;
