import React, { ReactNode, useState } from 'react';
import Icon from '@mdi/react';
import { View, StyleSheet, Pressable, Text } from 'react-native';
import { mdiClose } from '@mdi/js';
import { ToastContext } from '.';

type ToastProviderProps = { children: ReactNode };

const styles = StyleSheet.create({
  toastsContainer: {
    position: 'absolute',
    bottom: 90,
    right: 20,
    zIndex: 1000
  },
  toastsStyle: {
    color: '#151626',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: 10,
    width: 320,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  toastSuccess: { backgroundColor: '#22c55e' },
  toastFail: { backgroundColor: '#ef4444' },
  toastGeneric: { backgroundColor: '#3b82f6' },
  toastDismiss: {
    cursor: 'pointer',
    marginLeft: 'auto',
    width: 20,
    color: '#FFF'
  }
});

const Toast: React.FC<{
  message: ReactNode;
  success?: boolean;
  onClose: () => void;
}> = ({ message, success, onClose }) => {
  const style =
    success === undefined ? styles.toastGeneric : success ? styles.toastSuccess : styles.toastFail;
  return (
    <View style={[styles.toastsStyle, style]}>
      <Text>{message}</Text>
      <Pressable onPress={onClose}>
        <Icon path={mdiClose} style={styles.toastDismiss} />
      </Pressable>
    </View>
  );
};

/**
 * ToastProvider
 *
 * @param {*} { children }
 * @return {*}
 */
const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<{ id: number; message: ReactNode; success?: boolean }[]>([]);

  const showToast = (message: ReactNode, timeout: number, success?: boolean) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, success }]);
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, timeout);
  };

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <View style={styles.toastsContainer}>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            message={toast.message}
            success={toast.success}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </View>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
