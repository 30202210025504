import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { IUser } from '../../types/user';

interface IssueCredDetailsProps {
  user: IUser;
  attributes: string[];
  name: string;
}

const IssueCredDetails: React.FC<IssueCredDetailsProps> = ({ user, attributes, name }) => {
  const attributeValues: Record<string, string | boolean> = {
    first_name: user.tenant_name.split(' ')[0],
    last_name: user.tenant_name.split(' ')[1],
    firstName: user.tenant_name.split(' ')[0],
    lastName: user.tenant_name.split(' ')[1],
    location: 'Victoria',
    serverRoomAccess: 'false',
    expirationDate: '2025-12-31',
    email: user.email,
    photo: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
    wallet_id: user.wallet_id || 'N/A'
  };

  return (
    <View style={styles.container}>
      <Text style={styles.sectionTitle}>CREDENTIAL DETAILS</Text>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.label}>Name:</Text>
          <Text style={styles.value}>{name}</Text>
        </View>
      </View>

      <View style={styles.container}>
        <Text style={styles.sectionTitle}>ATTRIBUTES</Text>
        <View style={styles.section}>
          {attributes.map((attr, index) => (
            <View key={index} style={styles.row}>
              <Text style={styles.label}>{attr}:</Text>
              <Text style={styles.value}>{attributeValues[attr] || 'N/A'}</Text>
            </View>
          ))}
        </View>
      </View>

      <View style={styles.container}>
        <Text style={styles.sectionTitle}>ISSUED TO</Text>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.label}>Username:</Text>
            <Text style={styles.value}>{user.tenant_name}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Email:</Text>
            <Text style={styles.value}>{user.email}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Wallet ID:</Text>
            <Text style={styles.value}>{user.wallet_id || 'N/A'}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center'
  },
  section: {
    marginBottom: 20
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#6c757d',
    marginBottom: 10
  },
  row: {
    flexDirection: 'row',
    marginBottom: 10
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#6c757d',
    width: 150 // Adjust width as needed
  },
  value: {
    fontSize: 14,
    color: '#000',
    flex: 1
  }
});

export default IssueCredDetails;
