import React, { useEffect, useState } from 'react';
import { useTenantContext } from '../contexts';
import BlankModal from './BlankModal';
import IssueCredDetails from '../components/modals/IssueCredDetails';
import { IUser } from '../types/user';
import { useIssuerService } from '../services';
import Spinner from '../components/molecules/Spinner';
import { View } from 'react-native';
import { Picker } from '@react-native-picker/picker';
export interface SendUserCredModalProps {
  isVisible: boolean;
  onClose: () => void;
  user: IUser;
}

const SendUserCredModal: React.FC<SendUserCredModalProps> = ({ isVisible, onClose, user }) => {
  const issuerService = useIssuerService();
  const [loading, setLoading] = useState(false);
  const tenantContext = useTenantContext();
  const [credentialSchemas, setCredentialSchemas] = useState<any[]>([]);
  const [selectedSchema, setSelectedSchema] = useState<any | null>(null);
  const [isAnonCred, setIsAnonCred] = useState(true);
  const [attributes, setAttributes] = useState<string[]>([]);
  const [name, setName] = useState<string>('');

  useEffect(() => {
    const getCredentialSchemas = async () => {
      setLoading(true);
      const schemas = await issuerService.fetchCredentialSchemas();
      setCredentialSchemas(schemas);
      console.log(schemas);
      setLoading(false);
    };
    if (isVisible) {
      getCredentialSchemas();
      setSelectedSchema(null);
      setAttributes([]);
    }
  }, [isVisible]);

  useEffect(() => {
    if (selectedSchema) {
      setIsAnonCred(!!selectedSchema.ledger_id);
      setAttributes(selectedSchema.schema.attrNames || []);
      setName(selectedSchema.schema.name || '');
    } else {
      setAttributes([]);
    }
  }, [selectedSchema]);

  const sendCredential = async (data: IUser) => {
    setLoading(true);
    if (isAnonCred) {
      console.log(data);
      await issuerService.sendCredential(data);
    } else {
      const credentialInformation = {
        credentialName: name,
        credentialSubject: attributes.reduce(
          (acc, attr) => {
            acc[attr] =
              {
                first_name: user.tenant_name.split(' ')[0],
                last_name: user.tenant_name.split(' ')[1],
                firstName: user.tenant_name.split(' ')[0],
                lastName: user.tenant_name.split(' ')[1],
                location: 'Victoria',
                serverRoomAccess: 'false',
                expirationDate: '2025-12-31',
                email: user.email,
                photo: 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
                wallet_id: user.wallet_id || 'N/A'
              }[attr] || 'N/A';
            return acc;
          },
          {} as Record<string, string | boolean>
        )
      };
      console.log(credentialInformation);
      await issuerService.sendW3CCredential(data, credentialInformation);
    }
    await tenantContext.fetchReservations();
    onClose();
    setLoading(false);
  };

  return (
    <BlankModal
      title="Issue credential"
      isVisible={isVisible}
      onClose={onClose}
      onConfirm={() => sendCredential(user)}
      acceptText="Issue credential"
      isLoading={loading}>
      {loading ? (
        <View
          style={{
            minHeight: 241,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Spinner />
        </View>
      ) : (
        <>
          <Picker
            selectedValue={selectedSchema?.schema.name || null}
            onValueChange={(itemValue: any) => {
              const schema =
                credentialSchemas.find((schema) => schema.schema.name === itemValue) || null;
              setSelectedSchema(schema);
            }}>
            <Picker.Item label="Select a Credential Schema" value={null} />
            {credentialSchemas.map((schema, index) => (
              <Picker.Item key={index} label={schema.schema.name} value={schema.schema.name} />
            ))}
          </Picker>
          {attributes.length > 0 && (
            <IssueCredDetails user={user} attributes={attributes} name={name} />
          )}
        </>
      )}
    </BlankModal>
  );
};

export default SendUserCredModal;
