import { AuthContextProps } from 'react-oidc-context';
import { UserReservation } from '../types/reservation';
import BaseService from './base';

/**
 * UserService class
 *
 * @export
 * @class UserService
 */
export default class UserService extends BaseService {
  constructor(auth: AuthContextProps) {
    super(auth);
  }

  /**
   * Fetch reservation
   *
   * @param {string} reservation_id
   * @return {*}  {Promise<UserReservation>}
   * @memberof UserService
   */
  async fetchReservation(reservation_id: string): Promise<UserReservation> {
    const body = await this.get<UserReservation>(`/issuer/user/${reservation_id}`);

    return new UserReservation(body);
  }

  /**
   * Create wallet
   *
   * @return {*}  {Promise<boolean>}
   * @memberof UserService
   */
  async createWallet(): Promise<boolean> {
    const body = await this.get<boolean>('/issuer/user/onboard');

    return body === true;
  }
}
