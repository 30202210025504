import Tenant, { ITenant } from '../types/tenant';
import { INewUser, IUser, User } from '../types/user';
import { UserReservation } from '../types/reservation';
import { CredentialExchangeRecord, SchemaStorageResult } from '../types/credentials';
import { AuthContextProps } from 'react-oidc-context';
import UserService from './user';

/**
 * IssuerService class
 *
 * @export
 * @class IssuerService
 */
export default class IssuerService extends UserService {
  constructor(auth: AuthContextProps) {
    super(auth);
  }

  /**
   * Fetch tenant
   *
   * @return {*}  {Promise<Tenant>}
   * @memberof IssuerService
   */
  async fetchTenant(): Promise<Tenant> {
    const body = await this.get<ITenant>('/issuer/auth/tenant');

    return new Tenant(body);
  }

  /**
   * Fetch invitation
   *
   * @param {INewUser} data
   * @return {*}  {Promise<UserReservation>}
   * @memberof IssuerService
   */
  async fetchInvitation(data: INewUser): Promise<UserReservation> {
    const body = await this.post<UserReservation>('/issuer/auth/register', {
      email: data.email.toLowerCase(),
      name: data.name
    });

    return new UserReservation(body);
  }

  /**
   * Fetch reservations
   *
   * @return {*}  {Promise<UserReservation[]>}
   * @memberof IssuerService
   */
  async fetchReservations(): Promise<UserReservation[]> {
    const body = await this.get<UserReservation[]>('/issuer/auth/reservations');

    return body.map((reservation) => new UserReservation(reservation));
  }

  /**
   * Fetch users
   *
   * @return {*}  {Promise<IUser[]>}
   * @memberof IssuerService
   */
  async fetchUsers(): Promise<User[]> {
    const body = await this.get<IUser[]>('/issuer/auth/users');

    return body.map((user) => new User(user));
  }

  /**
   * Send credential
   *
   * @param {string} email
   * @param {string} reservationId
   * @param {string} walletId
   * @return {*}  {Promise<boolean>}
   * @memberof IssuerService
   */
  async sendCredential(user: IUser): Promise<boolean> {
    const body = await this.post<boolean>('/issuer/auth/send-credential', {
      email: user.email,
      tenant_id: user.tenant_id,
      wallet_id: user.wallet_id
    });

    return body === true;
  }

  /**
   * Send W3C credential
   *
   * @param {IUser} user
   * @return {*}  {Promise<boolean>}
   * @memberof IssuerService
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async sendW3CCredential(user: IUser, credentialInformation: any): Promise<boolean> {
    const body = await this.post<boolean>('/issuer/auth/send-w3c-credential', {
      email: user.email,
      tenant_id: user.tenant_id,
      wallet_id: user.wallet_id,
      credentialInformation: credentialInformation
    });

    return body === true;
  }

  /**
   * Send email
   *
   * @param {string} email
   * @return {*}  {Promise<void>}
   * @memberof IssuerService
   */
  async sendEmail(email: string): Promise<void> {
    await this.post<void>('/issuer/auth/send-email', {
      email: email
    });
  }

  /**
   * Fetch credentials
   *
   * @param {string} email
   * @return {*}  {Promise<CredentialExchangeRecord[]>}
   * @memberof IssuerService
   */
  async fetchCredentials(connection_id: string): Promise<CredentialExchangeRecord[]> {
    const body = await this.post<CredentialExchangeRecord[]>('/issuer/auth/fetch-credentials', {
      connection_id: connection_id
    });

    return body;
  }

  async fetchCredentialSchemas(): Promise<SchemaStorageResult[]> {
    const body = await this.get<SchemaStorageResult[]>('/issuer/auth/fetch-credential-schemas');
    return body;
  }
  /**
   * Delete user reservation
   *
   * @param {string} reservationId
   * @return {*}  {Promise<boolean>}
   * @memberof IssuerService
   */
  async deleteUserReservation(reservationId: string): Promise<boolean> {
    const body = await this.post<boolean>('/issuer/auth/delete-reservation', {
      reservation_id: reservationId
    });

    return body === true;
  }

  /**
   * Delete user
   *
   * @param {string} email
   * @return {*}  {Promise<boolean>}
   * @memberof IssuerService
   */
  async deleteUser(email: string): Promise<boolean> {
    const body = await this.post<boolean>('/issuer/auth/delete-user', {
      email: email
    });

    return body === true;
  }
}
